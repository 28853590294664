<template>
<b-form @submit.prevent>    
  <b-row>
     <b-col cols="6">
      <b-card-code title="Transporteur">
        <b-row>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Identité"
              label-for="hi-first-name"
              label-cols-md="4"
              class="p-0 m-0 text-bold"
            >
              <b-input-group class="input-group-merge ">
                xxxxxxxxx xxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Email"
              label-for="hi-email"
              label-cols-md="4"
              class="p-0 m-0 text-bold"
            >
              <b-input-group class="input-group-merge">
                xxxxxxxxxx@mameribj.com
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Téléphone"
              label-for="hi-email"
              label-cols-md="4"
              class="p-0 m-0 text-bold"
            >
              <b-input-group class="input-group-merge">
                +229 xxxxxxxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Permis"
              label-for="hi-email"
              label-cols-md="4"
              class="p-0 m-0 text-bold"
            >
              <b-input-group class="input-group-merge">
                C
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="N° Permis"
              label-for="hi-email"
              label-cols-md="4"
              class="p-0 m-0 text-bold"
            >
              <b-input-group class="input-group-merge">
                xxxxxxxx
              </b-input-group>
            </b-form-group>
          </b-col>
         
         
        </b-row>

       
      </b-card-code>

    </b-col>
    <b-col cols="6">
      <b-card-code title="Proposition et prix">
        <b-row>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Montant"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxxxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0"
              label="Départ"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxxxxx xxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
              label="Arrivé"
              class="p-0 m-0"
              label-for="hi-number"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                	27/02/2021, 14:00
              </b-input-group>
            </b-form-group>
          </b-col>
         
         
        </b-row>
      </b-card-code>

    </b-col>
    
    <b-col cols="6">
      <b-card-code title="Camion">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Catégorie"
              class="pt-0 mt-0"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge ">
                xxxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Type"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxxxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Année"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxxxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="N° Chassis	"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxxxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Immat"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxxxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Nbre Essieux"
              label-for="hi-email"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxxxx
              </b-input-group>
            </b-form-group>
          </b-col>
         
         
        </b-row>

       
      </b-card-code>

    </b-col>
    <b-col cols="6">
      <b-card-code title="Affreteur">
        <b-row>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Identité"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxxx xxx
              </b-input-group>
            </b-form-group>
          </b-col>
        <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Téléphone"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxxx
              </b-input-group>
            </b-form-group>
          </b-col>
       
         
         
        </b-row>
      </b-card-code>

    </b-col>
  </b-row>
  <b-row>
   
   <b-col cols="6">
      <b-card-code title="Offre de fret">
        <b-row>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Code"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxx
              </b-input-group>
            </b-form-group>
          </b-col>
          
         
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Publication"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxx
              </b-input-group>
            </b-form-group>
          </b-col>
          
           <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Départ"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                KPASSE ( OUIDAH )
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Destination"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                KPASSE ( OUIDAH )
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Date Départ"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                28/02/2021, 12:00
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Date Arrivé"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                28/02/2021, 12:00
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Marchandise"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Poids"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxx
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="py-0 my-0" cols="12">
            <b-form-group
            class="p-0 m-0 text-bold"
              label="Description"
              label-for="hi-first-name"
              label-cols-md="4"
            >
              <b-input-group class="input-group-merge">
                xxx
              </b-input-group>
            </b-form-group>
          </b-col>
          
        </b-row>
      </b-card-code>

    </b-col>

  </b-row>


</b-form>

</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'

import {
  BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BInputGroup, BInputGroupPrepend, BRow, BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import { codeHorizontalIcon } from './code'
// import { codeBasic } from './dcode'


export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    vSelect,
    BButton,
    flatPickr,

    BDropdown,
    BDropdownItem,
    BDropdownDivider,

  },
  directives: {
    Ripple,

  },
  data() {
    return {
      codeHorizontalIcon,
      
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      inlineDate: null,
      codeBasic,

      selected: { title: 'Chox du camion' },
      option: [{ title: 'Chox du camion' }, { title: 'RB 4526 (Chassis: 12345678)' }, { title: 'RB 4567 (Chassis: 12345678)' }, { title: 'RB 4896 (Chassis: 12345678)' }],

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
